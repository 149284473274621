import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { LogService } from '@/sevices';
import * as Log from '@/typings/log';
import { CTable } from '@cloudpivot-hermes/common-components';
let OrgPermissionLog = class OrgPermissionLog extends Vue {
    constructor() {
        super(...arguments);
        this.isShowFilter = false;
        this.resultType = {
            0: '成功',
            1: '失败',
        };
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'userName',
                key: 'userName',
                ellipsis: true,
                slots: { title: 'userNameTitle', name: '用户' },
            },
            {
                dataIndex: 'account',
                key: 'account',
                ellipsis: true,
                slots: { title: 'accountTitle', name: '账号' },
            },
            {
                dataIndex: 'urlDesc',
                key: 'urlDesc',
                ellipsis: true,
                slots: { title: 'urlDescTitle', name: '操作描述' },
            },
            {
                dataIndex: 'operationTime',
                key: 'operationTime',
                ellipsis: true,
                slots: { title: 'operationTimeTitle', name: '操作时间' },
            },
            {
                dataIndex: 'ip',
                key: 'ip',
                ellipsis: true,
                slots: { title: 'ipTitle', name: '操作IP' },
            },
            {
                dataIndex: 'source',
                key: 'source',
                ellipsis: true,
                scopedSlots: { customRender: 'source' },
                slots: { title: 'sourceTitle', name: '来源' },
            },
        ];
    }
    showFilter() {
        this.isShowFilter = !this.isShowFilter;
    }
    closeFilter() {
        this.isShowFilter = false;
    }
    source(i) {
        switch (i) {
            case 0:
                return '运营后台';
            case 1:
                return '开发平台';
            case 3:
                return '资产市场';
            default:
                return '未知来源';
        }
    }
    /**
     * 搜索
     */
    search(searchParams) {
        const params = {
            ...searchParams,
            logType: Log.PASSWORD_LOG,
            page: 1,
        };
        this.cTable.pagination.current = 1;
        this.showFilter();
        this.$refs.ctable.getTableList(params);
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            logType: Log.PASSWORD_LOG,
            page: params.page - 1,
        };
        const res = await LogService.getOpeateLogList(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.resultType = this.resultType[item.result];
            });
        }
        return res;
    }
};
__decorate([
    Ref()
], OrgPermissionLog.prototype, "cTable", void 0);
OrgPermissionLog = __decorate([
    Component({
        name: 'OrgPermissionLog',
        components: {
            CTable,
            FilterDrawer: () => import('@/components/sys-manage/log-info/filter-drawer'),
            FilterPasswordLog: () => import('@/components/sys-manage/log-info/filter-drawer/filter-password-log.vue'),
        },
    })
], OrgPermissionLog);
export default OrgPermissionLog;
